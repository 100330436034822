import { movePreviousStatus } from '@/utils/common.js'
import { config } from '@/constants'

export const Mixin =  {
  data() {
    return {
      research: {
        formData: {
          samplingDate: '',
        },
        sampleExpire: '20220306',
        startDate: '20220304',
        endDate: '20220306',
        sampleStartDate: '20220301',
        sampleEndDate: '20220306',
        days: [],
        sampleDays: [],
        times: [
          ['12:00', '15:00'],
          ['15:00', '17:00'],
          ['17:00', '19:00'],
          ['19:00', '21:00'],
        ],
        sampleExpireFlg: false,
      },
    }
  },
  created() {
    this.createTitleDesc()
    this.scrollTop()
    // ログイン日時が一定期間を過ぎていた場合、採取予定日を表示しない
    const checkLoginTime = sessionStorage.getItem('check_login_time')
    if (
      checkLoginTime &&
      new Date(checkLoginTime).getTime() >= this.chars8ToDate(this.research.sampleExpire).getTime()
    ) {
      this.research.sampleExpireFlg = true
    }

  },
  methods: {
    createTitleDesc() {
      //titleを設定する処理
      if (this.$route.meta.title) {
        document.title = this.$route.meta.title + '｜' + config.SITE_NAME
        const navbarBrand = document.querySelector('.navbar-brand')
        if (navbarBrand) {
          navbarBrand.innerHTML = this.$route.meta.title
        }
      }
      //// メタタグのdescription設定処理
      //if (this.$route.meta.desc) {
      //  document.querySelector("meta[name='description']").setAttribute('content', this.$route.meta.description)
      //} else {
      //  document.querySelector("meta[name='description']").setAttribute('content', '')
      //}
    },
    getCandidateDates: function(startDate, endDate) {
      let nowDate = 0
      let cnt = 0
      const days = []
      while(parseInt(endDate) >= parseInt(nowDate)) {
        cnt++
        if (cnt > 1000) break
        if (!nowDate) nowDate = startDate
        let date = this.chars8ToDate(nowDate)
        days.push(new Date(date))
        date.setDate(date.getDate() + 1)
        nowDate = this.dateTochars8(date)
      }
      return days
    },
    getFormatDateId: function(date, time) {
      let timeStr = ''
      if (time) {
        const hours = time.map(t => {
          return t.split(':')[0]
        })
        timeStr = hours[0] + hours[1]
      }
      return date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2) + timeStr
    },
    getFormatTime: function(dateTime) {
      return dateTime.substr(8, 2) + ':00～' + dateTime.substr(10, 2) + ':00'
    },
    getFormatDate: function(date) {
      const youbi = ['日', '月', '火', '水', '木', '金', '土']
      return ('0' + (date.getMonth() + 1)).slice(-2) + '/' + ('0' + date.getDate()).slice(-2) + ' (' + youbi[date.getDay()] + ')'
    },
    dateTochars8: function(date) {
      return date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
    },
    chars8ToDate: function(str) {
      return new Date(parseInt(str.substr(0, 4)), parseInt(str.substr(4, 2)) - 1, parseInt(str.substr(6, 2)))
    },
    chars12ToDate: function(str) {
      return new Date(parseInt(str.substr(0, 4)), parseInt(str.substr(4, 2)) - 1, parseInt(str.substr(6, 2)), parseInt(str.substr(8, 2)))
    },
    scrollTop() {
      setTimeout(() => {
        window.scrollTo({ top: 0 })
      })
    },
    back() {
      movePreviousStatus(this, false)
    },
    backLoggedIn() {
      movePreviousStatus(this, true)
    },
    async logout() {
      await this.resetToken()
      this.$router.replace({name: 'Login'})
    },
    async resetToken() {
      await this.$store.dispatch('member/resetToken')
    },
  }
}
