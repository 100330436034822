<template>
  <div class="container">
    <p class="mt-4 mb-3 text-left">
      本研究への応募を有難うございます。<br />
      以下の応募要件を確認し、本研究の説明動画を視聴してください。<br />
      その後、同意内容を確認し、応募を完了してください。<br /><br />
      唾液や口すすぎ液が入った容器をご自宅の冷凍庫で保管し、回収までの数日間～1週間ほど保管できます。
    </p>
    <div class="ipcheck mt-4">
      <form ref="form1" @submit.prevent>
        <div class="checkbox">
          <label for="check-1"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-1" @change="onChangeCheck" required />30歳以上の方</label>
        </div>
        <div class="checkbox">
          <label for="check-2" class="mb-2"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-2" @change="onChangeCheck" required />自宅で自ら水を含んで口をすすぐ口すすぎ液と唾液を採取できます。<br />（ご高齢の方など、ご家族の助けを借りても構いません）</label>
          <img src="../assets/image/explain1.jpg" class="w-100 pb-5" @click="popup">
        </div>
        <div class="checkbox">
          <label for="check-3" class="mb-2"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-3" @change="onChangeCheck" required />唾液の容器をご自宅の冷凍庫で冷凍し、回収までの数日間保管できます。</label>
          <img src="../assets/image/explain2.jpg" class="w-100 pb-5" @click="popup">
        </div>
        <div class="checkbox">
          <label for="check-4"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-4" @change="onChangeCheck" required />アンケートにオンラインで回答できます。<br />応募時に説明動画視聴をいれて10分程度かかります。<br />また、研究対象者に選定されてから、試料採取日や回収日を記入したり、アンケートに回答する時間は、すべて合わせて概ね20分ほどかかります。<br />（オンラインを推奨しますが、試料採取キットが送付されたあとでは、紙面回答も可能です。また、家族の助けを借りても構いません。）</label>
        </div>
        <div class="checkbox">
          <label for="check-5"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-5" @change="onChangeCheck" required />説明動画を視聴し、理解できます。</label>
        </div>
        <ul class="nav mt-3">
          <li><button type="button" class="btn_next btn btn-primary" @click="onClickConsentButton">同意する</button></li>
        </ul>
      </form>
    </div>
  </div>
  <div id="modal-container" @click="modalClose" ref="modal">
    <div><img src="" ref="modalImage"></div>
  </div>
</template>

<script>
import { Mixin } from '@/mixin.js'
import { checkStatus } from '@/utils/common.js'

export default {
  mixins: [Mixin],
  data() {
    return {
    }
  },
  async created() {
    if (this.$router.currentRoute.value.name == 'top') {
      this.$router.replace({name: 'Apply'})
    }
    // ステータスチェック
    await checkStatus(this, 1)
  },
  mounted() {
    if (localStorage.getItem('applyFlag') == 1) {
      Array.from(document.querySelectorAll('input[type=checkbox]'), elem => {
        elem.checked = true
      })
    }
  },
  methods: {
    onClickConsentButton() {
      if (!this.$refs.form1.reportValidity()) {
        alert('項目をすべてチェック頂くことで事前アンケートへ進めます。')
        return
      }

      // localStorageに同意フラグを保存
      localStorage.setItem('applyFlag', '1')

      // 動画画面へ
      this.$router.push({name: 'PreMovie'})
    },
    onChangeCheck() {
      if (localStorage.getItem('applyFlag') == 1) {
        Array.from(document.querySelectorAll('input[type=checkbox]'), elem => {
          if (!elem.checked) {
            localStorage.removeItem('applyFlag')
          }
        })
      }
    },
    popup(event) {
      this.$refs.modalImage.setAttribute('src', event.target.src)
      this.$refs.modal.style.display = 'block'
    },
    modalClose() {
      this.$refs.modal.style.display = 'none'
    }
  },
}
</script>

<style src="../assets/css/pages/questionnaire.css" scoped></style>
